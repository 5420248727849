///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //h2見出し
  &-2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
    @include mq-max(xs) {
      font-size: 28px;
    }
    &>small {
      display: block;
      font-size: 18px;
      font-style: italic;
      font-weight: 600;
      color: $keyColor;
      letter-spacing: 0.06em;
      margin-bottom: 5px;
      padding-left: 15px;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        background-color: $accentColor;
        width: 7px;
        height: 7px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) skew($skew);
      }
    }

    //大き目
    &--large {
      font-size: 36px;
      @include mq-max(xs) {
        font-size: 30px;
      }
    }
  }

  //h3見出し
  &-3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
    @include mq-max(xs) {
      font-size: 20px;
    }
    &--line {
      position: relative;
      padding-left: 12px;
      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 2px;
        position: absolute;
        left: 0;
      }
      &:before {
        background-color: $gray;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        background-color: $keyColor;
        top: 0;
        height: 50%;
      }
    }
    //三角
    &--tri {
      position: relative;
      padding-left: 18px;
      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 10px 0 0;
        border-color: $keyColor transparent transparent transparent;
        position: absolute;
        top: .4em;
        left: 0;
      }
    }
  }

  //modify
  &--center {
    text-align: center;
  }
  &--mbLarge {
    margin-bottom: 30px;
  }
}


//章見出し
.c-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
  padding: 15px 10px;
  background-color: $lightGray;
  transform: skew($skew);
  @include mq-max(sm) {
    padding: 15px 24px;
  }
  @include mq-max(xs) {
    font-size: 20px;
  }
  &>span {
    display: inline-block;
    transform: skew(-1 * $skew);
  }

  //章小見出し
  &--sub {
    font-size: 21px;
    font-weight: 500;
    background: none;
    padding: 4px 10px;
    margin-bottom: 23px;
    transform: none;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      background-color: $keyColor;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 1;
    }
    @include mq-max(xs) {
      font-size: 18px;
    }
    &>span {
      position: relative;
      transform: none;
      padding: 0 15px;
      background-color: $white;
      z-index: 2;
    }
  }
}