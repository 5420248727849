//テーブル
.c-tbl {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  th,td {
    border: 1px solid $bdrColor;
    padding: 15px 20px;
  }

  //デフォルト
  &__block {
    tbody{
      tr{
        @include mq-max(xs){
          &:not(:last-child){
            td{
              border-bottom: none;
            }
          }
          &:last-child {
            td {
              border-bottom-color: $keyColor;
            }
          }
        }
        th{
          width: 131px;
          border-color: $keyColor;
          padding: 15px 15px 15px 20px;
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          background-color: $lightGray;
          vertical-align: top;
          @include mq-max(xs){
            width: 100%;
            padding: 14px 20px;
            border-bottom: none !important;
            text-align: left;
          }
        }
        td{
          width: calc(100% - 131px);
          padding: 15px 20px 15px 26px;
          font-size: 16px;
          font-weight: 400;
          @include mq-max(xs) {
            width: 100%;
            padding: 14px 20px;
          }
        }
        th,td{
          border-left: none;
          border-right: none;
          @include mq-max(xs){
            display: block;
            width: 100%;
          }
        }
      }
    }
  }


  //テーブルリスト
  &__list {
    thead {
      th {
        font-size: 16px;
        font-weight: 500;
        padding: 8.5px 10px;
        white-space: nowrap;
      }
    }
    tbody {
      td {
        padding: 17.5px 20px;
        white-space: nowrap;
      }
    }

    &--name {
      width: 45%;
    }
    &--format {
      width: 20%;
    }
    &--maker {
      width: 13%;
    }
    &--performance {
      width: 13%;
    }
    &--units {
      width: 9%;
    }

  }


  //スクロール型
  &__scroll {
    @include mq-max(bp700) {
      overflow: hidden;
      overflow-x: scroll;
      padding: 15px;
      border: 1px solid #cecece;
      border-radius: 5px;
      &:before {
        content: "←スライドで表の詳細を確認できます→";
        color: #666;
        font-size: 90%;
        display: block;
        margin-bottom: 10px;
        text-align: center;
      }
      table {
        min-width: 700px;
        th, td {
          display: table-cell;
        }
        th {
          white-space: nowrap;
        }
      }
    }
  }

}
