//ボックス
.c-box {
  background: $white;
  border: 1px solid $gray;
  // border-radius: $border-radius;
  padding: 13px 10px 12px 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0
  }
  &--gray {
    background-color: $lightGray;
    border-color: $gray;
  }
  &--red {
    background-color: #FFF4F4;
    border-color: $accentColor;
  }

  &__heading {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 0;
    margin-bottom: 2px;
  }
}

.c-whiteBox {
  padding: 40px 50px;
  background-color: $white;
  @include mq-max(xs) {
    padding: 30px 20px;
  }
}