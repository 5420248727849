//サービスリスト（TOPで使用）
.c-serviceList {
  width: 100%;
  transform: skew($skew);
  position: relative;
  left: -40px;
  @include mq-max(content) {
    transform: none;
    left: 0;
  }
  &__inner {
    display: flex;
    position: relative;
    @include mq-max(content) {
      flex-direction: column;
    }
    &:after {
      content: '';
      display: block;
      background-color: $keyColor;
      transform: skew(-1 * $skew);
      z-index: 1;
      width: calc( (100vw - #{$contentWidth}) / 2 + 100px );
      height: 100%;
      position: absolute;
      top: 0;
      right: calc( ((100vw - #{$contentWidth}) / 2 + 40px) * -1 );
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        right: calc( ((100vw - #{$contentWidth}) / 2 + 20px) * -1 );
      }
      @include mq-max(content) {
        display: none;
      }
    }
  }

  &__label {
    background-color: $keyColor-light;
    display: flex;
    align-items: center;
    flex: 0 0 100px;
  }

  &__title {
    color: $white;
    margin-bottom: 0;
    padding: 0 15px;
    font-size: 18px;
    font-style: italic;
    text-align: center;
    transform: skew(-1 * $skew);
    @include mq-max(content) {
      transform: none;
      text-align: center;
      width: 100%;
      padding: 14px 15px;
    }
    &>span {
      display: block;
      @include mq-max(content) {
        display: inline-block;
      }
    }
    &-head {
      margin-right: 0;
      @include mq-max(content) {
        margin-right: .5em;
      }
    }
    // &-bottom {}
  }

  &__body {
    background-color: $keyColor;
    padding: 40px 90px 50px;
    flex: 0 0 calc(100% - 100px);
    z-index: 2;
    @include mq-max(content) {
      padding: 27px 22px 30px;
      flex: 0 0 100%;
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: auto;
      }
    }
  }

  &__list {
    transform: skew(-1 * $skew);
    @include mq-max(content) {
      transform: none;
      left: 0;
    }
  }

  &__item {
    border-bottom: 1px solid $white;
    &:last-child {
      border-bottom: none;
    }
    a {
      color: $white;
      font-size: 18px;
      display: flex;
      align-items: center;
      padding: 16.5px 0;
      position: relative;
      &:before {
        font-size: 32px;
        color: $white;
        margin-right: 11px;
      }
      &:hover {
        color: $white;
        .c-serviceList__more {
          &:before {
            width: 100%;
          }
        }
      }
    }

    //各リンク設定
    &--design {
      a {
        &:before {
          @include fa('f568');
        }
      }
    }
    &--process {
      a {
        &:before {
          @include fa('f013');
        }
      }
    }
    &--build {
      a {
        &:before {
          @include fa('f0ad');
        }
      }
    }
    &--manage {
      a {
        &:before {
          @include fa('f002');
        }
      }
    }
  }

  &__more {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    display: inline-block;
    position: relative;
    margin-left: 10px;
    &:before {
      content: '';
      display: inline-block;
      width: 0%;
      height: 2px;
      background-color: $accentColor;
      position: absolute;
      left: 0;
      bottom: -1px;
      transition: all $transition;
    }
  }




}