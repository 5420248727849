//平行四辺形ブロック

.c-paraBlock {
  box-shadow: $box-shadow;
  position: relative;
  transform: skew($skew);
  transition: all $transition !important;
  z-index: 2;
  background-color: $white;
  margin-bottom: 0 !important;
  @include mq-max(bp1200) {
    width: 95%;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  @include mq-max(sm) {
    transform: none;
  }
  &:hover {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  }
  &__desc {
    padding: 20px 70px !important;
    transform: skew(-1 * $skew);
    @include mq-max(content) {
      padding: 20px 25px 20px 35px !important;
    }
    @include mq-max(sm) {
      transform: none;
      padding: 15px 24px 50px !important;
    }
  }
  &__frame {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: (696 / 1152 * 100%);
    transform: skew(-1 * $skew) scale(1.3);
    @include mq-max(content) {
      padding-top: 80%;
    }
    @include mq-max(sm) {
      transform: none;
      padding-top: (175 / 290 * 100%);
    }
  }

  //パーツ調整
  .c-ttl-2 {
    color: $textColor;
  }
  p {
    color: $textColor;
  }
  .c-btn {
    transform: none;
    background-color: rgba($keyColor, .9);
    border-color: rgba($keyColor, .9);
    color: $white;
    @include mq-max(sm) {
      transform: skew($skew);
    }
    &__wrap {
      position: absolute;
      bottom: 0;
      right: -20px;
      @include mq-max(sm) {
        bottom: -32px;
        right: 50%;
        transform: translateX(50%);
        white-space: nowrap;
      }
    }
  }
}