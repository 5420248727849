// CVエリア

.c-cvArea {
  background-color: $keyColor;
  color: $white;
  &__inner {
    padding: 42px 0;
    margin: 0 auto;
    max-width: $contentWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq-max(content) {
      padding: 42px 25px;
    }
    @include mq-max(md) {
      flex-direction: column;
    }
  }
  &__catch {
    @include mq-max(md) {
      padding-bottom: 15px;
      margin-bottom: 25px;
      border-bottom: 1px solid $white;
      width: 100%;
    }
    @include mq-max(sm) {
      margin-bottom: 15px;
    }
    p {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.4;
      @include mq-max(md) {
        text-align: center;
      }
      @include mq-max(sm) {
        font-size: 20px;
      }
    }
  }

  &__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq-max(sm) {
      flex-direction: column;
    }
  }
  &__tel {
    margin-right: 24px;
    @include mq-max(sm) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  &__term {
    margin-bottom: 7px;
    font-size: 16px;
  }
  &__num {
    font-size: 31px;
    font-weight: 600;
    line-height: 1;
    position: relative;
    padding-left: 45px;
    &:before {
      @include fa('f095');
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    a {
      color: inherit;
      &:hover {
        color: inherit;
      }
    }
  }


  //パーツ調整
  .c-btn {
    font-size: 24px;
    font-weight: 600;
    padding: 22.5px 31px 22.5px 67px;
    transition: all $transition;
    &:hover {
      opacity: .7;
    }
    @include mq-max(xs) {
      font-size: 17px;
      padding: 16.5px 31px 16.5px 67px;
    }
    &:before {
      @include fa('f0e0');
      position: absolute;
      top: 54%;
      left: 36px;
      transform: translateY(-50%) skew(-1 * $skew);
    }
    &:after {
      content: none;
    }
    &:hover {
      background-color: $white;
      color: $keyColor;
    }

    // &__wrap {}

  }

}